<template>
  <main>
    <section class="hero">
    <div id="hero-slider" class="slider-pro slider-pro-hero">
        <div class="sp-slides">
            <div class="sp-slide slide1">
                <div class="container">
                    <div class="hero-wrapper row d-flex flex-wrap">
                        <div class="col">
                            <h1 class="hero-title">Discover,<br/> Collect and Sell <br/>Amazing NFT's</h1>
                            <p class="hero-sub-text">World's first multi-chain social NFT marketplace</p>
                        </div>
                        <div class="col">
                            <div class="slider-wrapper">
                                <div class="slider-div">
                                    <div class="sm-box-left sp-layer" data-horizontal="180" data-vertical="50" data-show-transition="right" data-hide-transition="up" data-show-delay="600" data-hide-delay="100">
                                        <div class="profile-card">
                                            <img src="/home/img/profile-lady.png">
                                            <h5>Creator</h5>
                                            <h4>@Johana</h4>
                                        </div>
                                    </div>
                                    <div class="card-center sp-layer" data-tilt data-horizontal="180" data-vertical="50" data-hide-transition="up" data-show-delay="750" data-hide-delay="1000">
                                        <div class="card-pattern">
                                            <div class="card-cont">
                                                <img class="card-img" src="/home/img/hero-card-img1.png" />
                                                <div class="nav-dot">
                                                    <img src="/home/img/nav-dot.svg">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sm-box-right sp-layer " data-show-transition="left" data-hide-transition="up" data-show-delay="600" data-hide-delay="100">
                                        <img src="/home/img/chart-sm.png">
                                        <h3>17.K SOL</h3>
                                        <h5>+ 1.53 %</h5>
                                    </div>
                                </div>
                            </div>
                            <!-- slider div -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="sp-slide slide2">
                <div class="container">
                    <div class="hero-wrapper row d-flex flex-wrap">
                        <div class="col-5">
                            <h1 class="hero-title">Launching ICY<br/>NFT Airdrop</h1>
                            <p class="hero-sub-text">Login to Meme Cake platform and get Icy NFT airdrop</p>
                            <a class="yellow-line-btn" target="_blank" href="https://memecake.notion.site/Meme-Cake-NFT-s-7905c24ff0524dd7b052bc143f7c9d95">Read Document</a>
                        </div>
                        <div class="col-7">
                            <div class="slider-wrapper">
                                <div class="slider-div">
                                    <div class="sm-box-left sp-layer" data-horizontal="180" data-vertical="50" data-show-transition="right" data-hide-transition="up" data-show-delay="600" data-hide-delay="100">
                                        <div class="profile-card">
                                            <img src="/home/img/slide2-left.png">
                                        </div>
                                    </div>
                                    <div class="card-center sp-layer" data-tilt data-horizontal="180" data-vertical="50" data-hide-transition="up" data-show-delay="750" data-hide-delay="1000">
                                        <div class="card-pattern">
                                            <div class="card-cont">
                                                <img src="/home/img/star-white.svg" />
                                                <h2>Mega NFT<br/>Airdrop</h2>
                                                <p>Airdropping 10,000 unique Icy NFT</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sm-box-tag sp-layer " data-show-transition="left" data-hide-transition="up" data-show-delay="600" data-hide-delay="100">
                                        <img src="/home/img/hashtag.png">
                                    </div>
                                    <div class="sm-box-right sp-layer " data-show-transition="left" data-hide-transition="up" data-show-delay="600" data-hide-delay="100">
                                        <img src="/home/img/slider2-right.png">
                                    </div>
                                </div>
                            </div>
                            <!-- slide2 end -->
                        </div>
                    </div>
                    </div>
            </div>
                <!-- slide3 -->
            <div class="sp-slide slide3">
                <div class="container">
                    <div class="hero-wrapper row d-flex flex-wrap">
                        <div class="col">
                            <h1 class="hero-title">Meme Cake<br/> Launchpad</h1>
                            <p class="hero-sub-text">Get in touch if you have an idea or project.</p>
                            <a class="primary-btn" href="https://forms.gle/wPHhBNLb1MnWN53Y6" target="_blank">Apply to List</a>
                        </div>
                        <div class="col">
                            <div class="slider-wrapper">
                                <div class="slider-div">
                                    <div class="sm-box-left sp-layer" data-horizontal="180" data-vertical="50" data-show-transition="right" data-hide-transition="up" data-show-delay="600" data-hide-delay="100">
                                        <div class="profile-card">
                                            <img src="/home/img/slide3-left.png">
                                        </div>
                                    </div>
                                    <div class="card-center sp-layer" data-tilt data-horizontal="180" data-vertical="50" data-hide-transition="up" data-show-delay="750" data-hide-delay="1000">
                                        <div class="card-pattern">
                                            <div class="card-cont">
                                                <img class="card-img" src="/home/img/slide3-frame.png" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sm-box-right sp-layer " data-show-transition="left" data-hide-transition="up" data-show-delay="600" data-hide-delay="100">
                                        <img src="/home/img/slide3-right.png">
                                    </div>
                                </div>
                            </div>
                            <!-- slide3 end -->
                        </div>
                    </div>
                    </div>
            </div>
        </div>
    </div>
    </section>
    <section class="content-area">
    <!-- content area 1 -->
    <div class="container content-area-one d-flex">
        <div class="left-col">
            <img src="/home/img/launchpad.png">
        </div>
        <div class="right-col">
            <div class="col-text">
                <h1 class="main-title">Meme Cake<br/>Launchpad</h1>
                <p class="sub-text">Kickstart your initial NFT offering with us. We provide minting, marketing and marketplace support for your dream project. </p>
                <a class="primary-btn" href="https://forms.gle/wPHhBNLb1MnWN53Y6" target="_blank">Apply to List</a>
            </div>
        </div>
    </div>
    <!-- content area 2 -->
    <div class="process">
        <div class="container content-area-two d-flex">
            <div class="left-col">
                <img src="/home/img/process.svg" alt="The Process" />
                <h1 class="main-title">The Process</h1>
                <p class="sub-text">World's first social NFT <br> multi-chain marketplace</p>
            </div>
            <div class="right-col">
                <div class="card-wrapper">
                    <div class="content-card">
                        <h2 class="numbering">
                            01</h2>
                            <h2 class="card-title">Apply for Launchpad</h2>
                            <p>
                                Get in touch if you have an idea or artwork and want to showcase it to the world.
                            </p>
                    </div>
                    <div class="content-card">
                        <h2 class="numbering">
                            02</h2>
                            <h2 class="card-title">Mint with MemeCake</h2>
                            <p>
                                Mint your awesome artwork with our custom bakery tools.
                            </p>
                    </div>
                    <div class="content-card">
                        <h2 class="numbering">
                            03</h2>
                            <h2 class="card-title">Initial NFT Offering</h2>
                            <p>
                                We support private pre-sale and public sales for verified projects.
                            </p>
                    </div>
                    <div class="content-card">
                        <h2 class="numbering">
                            04</h2>
                            <h2 class="card-title">Marketplace</h2>
                            <p>
                                List your NFT's for public trading. 
                                Once you list people can trade your NFT either by buying or selling.
                            </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- content area 3 -->
    <div class="content-area-three">
        <div class="title-section">
            <h1 class="main-title m-0">Cake Club</h1>
            <h1 class="title2">Rich & Icy Initial NFT Offering</h1>
            <p class="sub-text"> 7,777 randomly generated unique collectibles Rich NFTs. <br> 
                Available on  <a style="color:rgb(0 208 146);" href="https://entrepot.app/marketplace/memecake" target="_blank">Entrepot</a>.</p>
        </div>
        <div class="graphics-section">
            <div class="d-flex section-wapper">
                <div class="pattern-left">
                    <img src="/home/img/pattern-left.png">
                </div>
                <div class="card-graphics">
                    <img src="/home/img/card-graphics.png">
                </div>
                <div class="pattern-right">
                    <img src="/home/img/pattern-right.png">
                </div>
            </div>
        </div>
        <div class="og-benefits container">
            <div class="row">
                <div class="col-4">
                    <h1 class="main-title mb-0">OG<br/>Benefits</h1>
                </div>
                <div class="col-4">
                    <div class="combo-content">
                        <img class="icon" alt="Access" src="/home/img/benefits/user.svg" />
                        <p>Own Rich NFT and get eligible for 1:1 Icy Airdrop</p>
                    </div>
                </div>
                <div class="col-4">
                    <div class="combo-content">
                        <img class="icon" alt="Access" src="/home/img/benefits/token.svg" />
                        <p>Meme Cake Token airdrop based on the rarity of Icy NFT</p>
                    </div>
                </div>
                <div class="col-4">
                    <div class="combo-content">
                        <img class="icon" alt="Access" src="/home/img/benefits/access.svg" />
                        <p>Get early access to launchpad</p>
                    </div>
                </div>
                <div class="col-4">
                    <div class="combo-content">
                        <img class="icon" alt="Access" src="/home/img/benefits/rewards.svg" />
                        <p>Join the NFT staking program and unlock staking rewards.</p>
                    </div>
                </div>
                <div class="col-4">
                    <div class="combo-content">
                        <img class="icon" alt="Access" src="/home/img/benefits/return.svg" />
                        <p>Burn your NFT’s and receive MCAKE tokens in return</p>
                    </div>
                </div>
            </div>
            <div class="button-grp">
                <a  class="blue-btn" href="https://twitter.com/realmemecake" target="_blank">
                <span><img src="/home/img/twitter.png" /></span>Follow Twitter</a>

                <a class="ghost-btn" href="https://discord.gg/WFkRssvNtW" target="_blank">
                    Join Discord</a>
                <a class="blue-btn" href="https://t.me/meme_cake" target="_blank">
                <span><img src="/home/img/telegram.png" /></span>Join Telegram </a>
            </div>
        </div>
    </div>
    </section>
     <section class="coming-soon">
            <div class="container">
                <h1 class="main-title"> Launching soon</h1>
                <div class="project-cards">
                    <div class="d-flex section-wapper">
                        <div class="project-left">
                            <img src="/home/img/project-left.png">
                            <div class="project-desc">
                                <h3> Da Vinci Club </h3>
                                <div class="soon-label">
                                    <span>Coming soon</span>
                                </div>
                            </div>
                        </div>
                        <div class="main-project">
                            <img src="/home/img/main-project.png">
                            <div class="project-desc">
                                <h3>Icy</h3>
                            </div>
                        </div>
                        <div class="project-right">
                            <img src="/home/img/project-right.png">
                            <div class="project-desc">
                                <h3>Meta Monkey</h3>
                                <div class="soon-label">
                                    <span>Coming soon</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
                <section class="process-content">
            <div class="container">
                <h1 class="main-title">A New Trading Experience</h1>
                <div class="row">
                    <div class="col">
                        <div class="process-card">
                            <img src="/home/img/process/wallet.png" alt="wallet" />
                            <h4 class="inner-title">Setup your wallet</h4>
                            <p>Connect with one of our available wallets of your choice by clicking the wallet icon from the top right corner.</p>
                        </div>
                    </div>
                    <div class="col">
                        <div class="process-card">
                            <img src="/home/img/process/buy-sell.png" alt="wallet" />
                            <h4 class="inner-title">Buy or sell</h4>
                            <p>Browse the collections and buy or sell the NFT you want in a few clicks.</p>
                        </div>
                    </div>
                    <div class="col">
                        <div class="process-card">
                            <img src="/home/img/process/shill.png" alt="wallet" />
                            <h4 class="inner-title">Shill your project</h4>
                            <p>Use our social media platform to market your NFT or Project and bring awareness.</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="social-media">
                        <div class="card-wide">
                            <div class="left-title">
                                <h1 class="main-title m-0">
                                    Memecake<br/>Social Media
                                </h1>
                            </div>
                            <div class="right-content">
                                <p>
                                    A space where you can post your NFT and create discussions. Create remarkable contents and gain followers. Test the waters before you launch and get feedback from the community.
                                </p>
                                <a href="/feed" class="link-btn">Post Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  </main>
</template>
<style scoped>
 @import '/home/css/style.css';
 @import '/home/css/slider-pro.min.css';
 .hero .slide1 , .hero .slide2 , .hero .slide3{
    width: 100%;
 }
 .slider-pro-hero , .sp-slides{
     height: 100%;
 }
 .slider-pro-hero{
     overflow: hidden;
 }
.hero .slide1{
    z-index: 3;
}

</style>
<script>
export default {
  beforeCreate: async function () {
    var plugin = document.createElement("script");
    plugin.setAttribute( "src", "/home/js/all.js");
    plugin.async = true;
    await document.head.appendChild(plugin);
  },
  mounted() {
       
  },
}
</script>